<template>
    <div style="width: 100%; min-width: 1200px; height: 100vh; position: relative; overflow: hidden; user-select: none;">
      <div id="home">
        <div class="world" 
        style="z-index: 1; transition: all 1s;"
        :style="`opacity: ${opac}; 
        ${(isMac && fangda>= 2)? 'transform: scale(.9)': 
        (!isMac && fangda>= 1.5 && win_width> 2000 )? 'transform: scale(1.3)': 'transform: scale(1)'}`"
        >
          <!-- +'; transform: scale('+ 1 / fangda +');' -->
            <div class="world-globe">
                <div class="world-globe-pole"></div>
                <div class="world-globe-doms-container"></div>
                <div class="world-globe-halo"></div>
            </div>
        </div>
      </div>
      <!-- :style="`opacity: ${opac}; ${(isMac && fangda>= 2)? 'transform: scale(.9)': (!isMac && fangda>= 1.5)? 'transform: scale('+ 1 / fangda +')': 'transform: scale(1)'}`" -->

      <!-- width: 65.86vh;
  height: 65.86vh; -->

      <star style="width: 100%; height: 100%; position: absolute;"></star>

      <img src="./assets/home/bg_xuan.png" class="bg_xuan z20"/>
      <img src="./assets/home/home_logo.png" class="home_logo z_20"/>

      <div class="home_title_box" style="opacity: 0;"
      :style="`opacity: ${opac} `">
        <el-image :src="title1" fit="cover" class="home_title1 z_20"></el-image>
        <el-image :src="title2" fit="cover" class="home_title2 z_20"></el-image>

        <el-image :src="title3" fit="cover" class="home_title3 z_20"></el-image>
        <el-image :src="title4" fit="cover" class="home_title4 z_20"></el-image>
      </div>

      <!-- <el-image :src="title5" fit="cover" class="home_title5 "></el-image> -->
      <el-image :src="bgl" fit="cover" class="bgl" style="z-index: 1; opacity: 0;"
      :style="`opacity: ${opac} `"></el-image>
      <el-image :src="bgr" fit="cover" class="bgr" style="z-index: 1; opacity: 0;"
      :style="`opacity: ${opac} `"></el-image>
      

    </div>
</template>

<script>


// import { WEBGL } from './WebGL';
import * as THREE from './starBg/js/three.min.js'
// import * as THREE from 'three'

import img1 from './assets/1.jpg';
import img2 from './assets/2.jpg';
import img3 from './assets/3.jpg';
import img4 from './assets/4.jpg';
import img5 from './assets/5.jpg';
import img6 from './assets/6.jpg';
import img7 from './assets/7.jpg';
import img8 from './assets/8.jpg';
import img9 from './assets/9.jpg';
import img10 from './assets/10.jpg';
import img11 from './assets/11.jpg';
import img12 from './assets/12.jpg';
import img13 from './assets/13.jpg';
import img14 from './assets/14.jpg';
import img15 from './assets/15.jpg';
import img16 from './assets/16.jpg';

import tie_guang from './assets/tie_guang.png'
import {earth, stop_earth} from './ballJs/earth.js'
import star from './starBg/star.vue'

import title1 from './assets/home/home_title1.png';
import title2 from './assets/home/home_title2.png';
import title3 from './assets/home/home_title3.png';
import title4 from './assets/home/home_title4.png';
import title5 from './assets/home/bg_btm.png';
import bgl from './assets/home/bgl.png';
import bgr from './assets/home/bgr.png';



export default {
  name: 'HomeView',
  components: {
    star
  },  
  data() {
    return {
      opac: 0,
      fangda: 1,

      title1,
      title2,
      title3,
      title4,
      title5,
      bgl,
      bgr,
      SPEED: 3,

      resizeFun: null,
      isMac: false,
      win_width: 0
    }
  },

  mounted() {
    console.log(window.innerWidth)
    this.SPEED= 3
    let that= this

    this.win_width= window.innerWidth


    document.getElementById('home').style.overflowX= 'hidden';

    if(/Mac|iPod|iPhone|iPad/.test(navigator.platform)){
      this.isMac= true
    }

    this.fangda= window.devicePixelRatio
    console.log('系统屏幕放大倍率', this.fangda)
    earth(); 
    setTimeout(() => {
      this.opac= 1;
    }, 400);

    // return;
      
    // window.innerWidth = 1300
    // window.innerHeight = 650
    // if (WEBGL.isWebGLAvailable() === false) {
    //   document.body.appendChild(WEBGL.getWebGLErrorMessage());
    // }
    var renderer, scene, camera, controls;
    var groupArr = [];
    let picCe
    var flag = false;
    //z主贴图
    var picArr = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16];
    //侧边发光贴图
    var picArrCe = [tie_guang]
    //远近距离
    var near_distance = 35
    //图片个数
    var pic_num = 16;

    if(that.SPEED== 0){
      return;
    }
      // setTimeout(() => {
        init();
        animate();
      // }, 1000);
      
    
    function init() {
      //  alpha: true   透明背景
      // console.log(window)
      // console.log(window.devicePixelRatio)
      renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      // console.log(window.innerWidth)
      renderer.setSize(window.innerWidth, window.innerHeight* 0.933);   //850
      // renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      renderer.gammaInput = true;
      renderer.gammaOutput = true;
      setTimeout(() => {
        // console.log(window.innerWidth / window.devicePixelRatio)
        renderer.domElement.style.setProperty("width",`${window.innerWidth / window.devicePixelRatio}`,"important") ;
        if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
          // 当前设备是移动设备
          console.log('当前设备是移动设备');
        
          renderer.domElement.style.setProperty("width",`100%`,"important") ;
          renderer.domElement.style.setProperty("height",`100vh`,"important") ;

        }

        console.log(renderer.domElement.style.width)
        // renderer.domElement.style.height= `${window.innerHeight}`;
        renderer.domElement.style.opacity= 0;
        renderer.domElement.style.zIndex= 10;
        renderer.domElement.style.position= 'absolute';
        let HOME= document.getElementById('home')
        HOME.appendChild(renderer.domElement);

        // 执行动画
        function animateIn(element) {
          var opacity = 0;
          var interval = setInterval(function() {
            if (opacity < 0.9) {
              opacity += 0.1;
              element.style.opacity = opacity;
            } else {
              clearInterval(interval);
            }
          }, 40); // 每100毫秒增加0.1的透明度
        }
        
        // 动画开始
        animateIn(renderer.domElement);

      }, 2000);
      // renderer.setClearColor(0x000000,0);  //透明背景

      // if (!renderer.extensions.get('OES_texture_float')) {
      //   alert('OES_texture_float not supported');
      //   throw 'missing webgl extension';
      // }
      // if (!renderer.extensions.get('OES_texture_float_linear')) {
      //   alert('OES_texture_float_linear not supported');
      //   throw 'missing webgl extension';
      // }
      if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
          // 当前设备是移动设备
          console.log('当前设备是移动设备');
          near_distance = 420

        }

      camera = new THREE.PerspectiveCamera(near_distance, window.innerWidth / window.innerHeight, 1, 430);
      camera.position.set(0, 20, 320);
      scene = new THREE.Scene();
      function initScene() {
        //给场景添加天空盒子纹理
        var cubeTextureLoader = new THREE.CubeTextureLoader();
        // cubeTextureLoader.setPath('./img/');
        //六张图片分别是朝前的（posz）、朝后的（negz）、朝上的（posy）、朝下的（negy）、朝右的（posx）和朝左的（negx）。
        // var cubeTexture = cubeTextureLoader.load([

        //   // right, left,
        //   // top, bottom,
        //   // front, back

        // ]);
        scene = new THREE.Scene();
        // scene.background = cubeTexture;
        // scene.background = null;
      }
      initScene();
      var light = new THREE.PointLight(0xffffff);
      light.position.set(0, 1000, 0);
      scene.add(light);
      var ambient = new THREE.AmbientLight(0xffffff, 1);
      scene.add(ambient);
      for (let i = 0; i < pic_num; i++) {
        let group = new THREE.Group();
        scene.add(group);
        groupArr.push(group)
      }

      let geometry = new THREE.BoxGeometry(46, 32, 0.7, 600, 1);
      //需要长：280，高300 平分6分，60度，中间有间隙取50度，  通过公式，为L=n× π× r/180，L=α× r。其中n是圆心角度数，r是半径，L是圆心角弧长得 r=320,n=50,弧度=280，
      //100=100*3.14*r/180 r=57.3


      /**
       * zzzz
      */
      const arcCurve = new THREE.ArcCurve(0, 0, 100, 0, Math.PI * 2, false)
      // console.log(arcCurve);

      // geometry.vertices = [];
      // for (var i = -100; i < 100; i += 1) {
      //   var x = i / 100;
      //   var y = Math.sin(x * 10);
      //   geometry.vertices.push(new THREE.Vector3(x, y, 0));
      // }

      // console.log(geometry)
      geometry.vertices.forEach(function (item) {
        // console.log(Math.sqrt(120*120 - item.x * item.x) - 120)
        item.z += Math.sqrt(120 * 120 - item.x * item.x) - 120;
      })
      /**************************************************************/



      var materialPicBg = new THREE.MeshBasicMaterial({
        color: '#000',
        // color: 'rgba(0,0,0,0)',  //图片背景色
        opacity: 0.58,
        transparent: true,
        // roughness: 0, 
        metalness: 0,
      });
      picCe = new THREE.TextureLoader().load(picArrCe[0]);

      var materialPicCeImg = new THREE.MeshBasicMaterial({
        map: picCe,
        transparent: true,
        opacity: 0.3,  //侧边厚度透明度
        side: THREE.DoubleSide
      });

      let materialPicImgArr = []
      let materialArr = []
      for (let i = 0; i < pic_num; i++) {
        //加载图片
        let TemPic = new THREE.TextureLoader().load(picArr[i])
        //图片材料
        let materialPicImg = new THREE.MeshBasicMaterial({
          map: TemPic,
          transparent: true,
          opacity: 0.99,
          side: THREE.DoubleSide,
        });
        materialPicImgArr.push(materialPicImg)
        let material = []
        //6面的图片材料
        material.push(materialPicCeImg, materialPicCeImg, materialPicCeImg, materialPicBg, materialPicImgArr[i], materialPicBg)
        let cube = new THREE.Mesh(geometry, material);

        cube.position.set(0, -8, 136);
        groupArr[i].add(cube)
        groupArr[i].rotateY(Math.PI * 2 / pic_num * i);
        if (i !== 0) {
          groupArr[0].add(groupArr[i])
        }
      }
    }
    function onResize() {
      renderer.setSize(window.innerWidth, window.innerHeight);
      camera.aspect = (window.innerWidth / window.innerHeight);
      camera.updateProjectionMatrix();
    }
    this.resizeFun= onResize
    window.addEventListener('resize', onResize, false);
    function animate() {
      requestAnimationFrame(animate);
      render();
    }
    function render() {
      if(that.SPEED== 0){
        return
      }
      // console.log(that.SPEED)
      var t = (Date.now() / 1000);
      //angle/t=Math.PI*2/360
      var angle_current = Math.PI * 2 / 360 * t;
      groupArr[0].rotation.y = - angle_current * that.SPEED;
      groupArr[0].rotation.x = 50;
      // console.log(groupArr)
      renderer.render(scene, camera);
    }
  },

  methods: {
    
  },
  beforeDestroy(){
    stop_earth()
    this.SPEED= 0;
  },
  destroyed(){
    window.addEventListener('resize', this.resizeFun, false);
    this.resizeFun= null

  }

}
</script>



<style>
body{
  margin: 0;
}
#home {
  margin: 0px;
  width: 100%;
	height:100vh;
  background: url('./assets/home/bg.png') 100% 100%  no-repeat;
  position: relative;
  /* 星空 */
  /* background: url('../assets/bg.gif') repeat;  */

}

#info {
  position: absolute;
  top: 0px;
  width: 100%;
  color: #ffffff;
  padding: 5px;
  font-family: Monospace;
  font-size: 13px;
  text-align: center;
}

a {
  color: #ff0080;
  text-decoration: none;
}

a:hover {
  color: #0080ff;
}

#threeContainer {
  width: 100%;
  height: 400px;
}


.world {
  position: absolute;
  width: 65.86vh;
  height: 65.86vh;
  top: 15.384vh;
  left: calc(50% - 32.93vh);
  cursor: unset !important;
  /* cursor: pointer;
  cursor: move;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  cursor: grab; */

  /* width: 600px;
  height: 600px;
  top: 15.384vh;
  left: calc(50% - 300px); */
}

/* 地球相关 */
.world-globe {
  width: 0;
  height: 0;
  width: 100%;
	height: 100%;
}

.world-globe-pole {
  /* position: absolute; */
  width: 600px;
  height: 600px;
  /* left: -265px;
  top: -265px; */
  border-radius: 50% 50%;
  /* background-color: #fff; */
}

.world-globe-doms-container {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0;
  height: 0;
}

.world-globe-halo {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 730px;
  height: 715px;
  margin-left: -368px;
  margin-top: -350px;
}

.info {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 10px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 12px;
}

.info-desc {
  color: #ddd;
  font-size: 10px;
}

.z_20{
  z-index: 20;
}
.bg_xuan{
    width: 600px;
    height: 360px;
    position: absolute;
    top: -60px;
    right: -90px;
    opacity: .3;
}
.home_logo{
  width: 100px;
	height: 75px;
  position: absolute;
  top: 40px;
  right: 110px;
  z-index: 21;
}
.home_title_box{
  width: 35vw;
  position: absolute !important;
  top: 30vh;
  left: 5vw;
  z-index: 22;
}
.home_title1{
  width: 100%;
  min-width: 448px;
}
.home_title2{
  width: 77%;
  min-width: 345px;
  margin-top: -54px;
}
.home_title3{
  position: absolute !important;
  bottom: 12px;
  left: 180px;
  width: 180px;
}
.home_title4{
  position: absolute !important;
  top: -34px;
  left: -28px;
  width: 100px;
}
.home_title5{
  position: absolute !important;
  bottom: -50px;
  left: 0;
  width: 100%;
}
.bgl{
  position: absolute !important;
  top: 30px;
  left: 0;
  width: 50px;
}
.bgr{
  position: absolute !important;
  bottom: 10vh;
  right: 0;
  width: 90px;
}
</style>