//导入axios
import axios from 'axios';
import qs from 'qs';
const HTTP= 'https://zthyoa.com/jeecg-boot';  //新域名

// const HTTP= 'http://192.168.110.39:8280/jeecg-boot';


//创建新的axios 实例
const service = axios.create({
  //公共接口（线下地址、测试地址、线上地址）
//   baseURL: process.env.VUE_APP_BASE_API,
  baseURL: HTTP,
  //超时时间，单位是ms
  timeout: 5000 ,
  headers: {
    // "Content-Type": "application/json;charset=UTF-8",
    // "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  }
})

//2.请求拦截器
service.interceptors.request.use(
  config => {
    // if(config.method== 'get'){
    //   config.headers['Content-Type']= 'application/x-www-form-urlencoded;charset=UTF-8';
    // }else  if(config.method== 'post' || config.method === 'put' || config.method === 'delete'){
    //   config.headers['Content-Type']= 'application/json;charset=UTF-8';
    //   // config.data = qs.stringify(config.data)
    // }

    
    // console.log(config)

    if(config.url== '/manager/hyNew/getVideo'){
      config.headers['Content-Type']= 'multipart/form-data';
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

//3.响应拦截器
service.interceptors.response.use(
 //使用Promise进行处理和响应
  response => {
    const res = response.data
    if (res.code != 200) {
      //配置服务器返回的状态码及返回的提示信息
      if (res.code == 400) {
        return res;
        // return Promise.reject(new Error(res.message ))
      }
      if(res.code== 500){
        return res;
      }
      if(res.code=== 0){
        return res
      }
      return Promise.reject(new Error(res.message ))
    }else {
      return res
    }
  },
  error => {

    const errorInfo = error.response;
    console.log('errorInfo:', error)
    if(errorInfo.status=== 403){
      // router.push({
      //   path: '/error/403'
      // })
    }

    console.log('err' + error)
    return Promise.reject(error)
  }
)

//4.导入文件
export default service

