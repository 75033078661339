//http.js
//导入封装好的axios实例
import request from './request'

const http = {

    //Get请求 
    get(url,params){
        const config={
            method :'get',
            url:url
        }
        if(params) config.params = params
        return request(config)
    },
    
	//POST请求
    post(url,params, type){

        const config = {
            method: 'post',
            url:url
        }
        if(params) {config.data = params};

        return request(config)
    },

    url: {
        news_list: '/manager/hyNew/list',  //新闻列表
        news_detail: '/manager/hyNew/queryById',  //新闻详情
        news_upDownList: '/manager/hyNew/upDownList',  //新闻详情上下页
        hyRelation_add: '/manager/hyRelation/add',  //联系我们提交表单
        search_company: '/sys/dictItem/list',  //公司模糊搜索框  //招聘类型查询
        search_job: '/manager/hyPost/queryByPostType',  //按照职位类别查询招聘岗位
        job_deliver: '/manager/hyInvite/add',  //职位投递
        get_video: '/manager/hyNew/getVideo',  //获取加入我们视频
    }

}
//导出
export default http

