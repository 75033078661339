import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueI18n from 'vue-i18n';

// import ElementUI from 'element-ui';
import {
  Carousel,
  CarouselItem,
  Pagination,
  Button,
  Form,
  FormItem,
  Input,
  MessageBox,
  Message,
  Image
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


import './common/pub_css.css';  // 全局公共样式
import 'swiper/swiper-bundle.min.css';
import animated from "animate.css";
// Vue.use(animated)

Vue.config.productionTip = false;
Vue.prototype.axios = axios;
Vue.prototype.$message = Message;
Vue.prototype.$messagebox = MessageBox

// Vue.use(ElementUI);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Pagination);
Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Image);

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: localStorage.getItem('languageSet') || 'zh',   //从localStorage里获取用户中英文选择，没有则默认中文
  messages: {
    'zh': require('./components/language/zh'),
    'en': require('./components/language/en')
  }
})

const mobileType= function(){
  let type= 1;
  if ((navigator.userAgent.match(/(Android)/i))) {
    type= 1
  }
  if ((navigator.userAgent.match(/(pad|pod|iPhone|iPod|ios|iPad|WebOS)/i))) {
    type= 2
  }

  return type
}

Vue.prototype.$mobileType= mobileType()


const echarts = require("echarts");
Vue.prototype.$echarts = echarts



new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')



router.beforeEach((to, from, next) => {
  // console.log(to, from)
  next()
})

router.afterEach((to, from, next) => {
  // console.log(to, from)

  if (to.meta.title) {
    document.title = to.meta.title
  }

  if (from.name == 'jobList' || from.name== 'wapJobList') {
    return;
  }

  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
});
