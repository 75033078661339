import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    wap_index: null,  //手机导航下标
  },
  getters: {

  },
  mutations: {
    change_wapIndex(state, i){
      state.wap_index= i
    }
  },
  actions: {

  },
  modules: {

  }
})
