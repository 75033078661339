<template>
  <div>

  </div>
</template>

<script>
import {star} from './js/datouwang.js'


export default {
     mounted(){
          star()
     }
}
</script>

<style>

</style>