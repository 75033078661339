<template>
  <div class="dis line_b">
     <div class="dis_between" style="margin-top: 240px; width: 100%;">
          <div style="width: 36px; height: 36px;" v-show="page_index<= 0"></div>
          <div style="width: 36px; height: 36px; flex-shrink: 0; cursor: pointer; user-select: none;" 
          :style="page_index<= 0? 'cursor: not-allowed': ''" 
          @click="page_index> 0? page(-1): ''"
          v-show="page_index> 0">
               <el-image :src="history_left" fit="cover"></el-image>
          </div>
          <div class="line" :style="page_index< page_count? '': 'opacity: 0;'"></div>
          <div style="width: 36px; height: 36px; flex-shrink: 0; cursor: pointer; user-select: none;"
          :style="page_index>= page_count? 'cursor: not-allowed': ''" 
          @click="page_index< page_count? page(1): ''"
          v-show="page_index< page_count">
               <el-image :src="history_right" fit="cover"></el-image>
          </div>
          <div style="width: 36px; height: 36px;" v-show="page_index>= page_count"></div>

     </div>

     <div class="his_conb_over">
          <div class="his_conb" :style="'left: calc(930px * '+ page_index+ '* -1);'">
               <div class="his_item" v-for="item, i in his_arr" :key="i">
                    <div class="item_pic">
                         <el-image :src="history_box" fit="cover"></el-image>
                         <div class="dis_center" style="width: 100%; height: 100%; position: absolute; top: 0; left: 0;">
                              <el-image :src="item.pic" fit="cover" class="item_pic_con"></el-image>
                         </div>
                         
                    </div>
                    <div class="dis_center" style="width: 100%; height: 146px; position: relative;">
                         <div class="item_time">
                              <div class="dis_column item_con">
                                   <div class="f_12" style="color: #fff;">{{ item.year }}</div>
                                   <div class="f_12" style="color: #fff;">{{ $t(item.month) }}</div>
                              </div>
                         </div>
                    </div>
                    <div style="width: 260px; height: 29px; position: relative;">
                         <el-image :src="history_title" fit="cover"></el-image>
                         <div class="dis_center f_16" style="width: 100%; height: 100%; color: #fff; position: absolute; top: 0; left: 0; letter-spacing: 4px;">{{ item.title }}</div>
                    </div>
                    <div class="f_14 item_text">
                         {{ $i18n.locale=='zh'? item.zh: item.en }}
                    </div>
               </div>

               <div class="dis_between last_item" style="padding: 0 20px; box-sizing: border-box; width: 930px; background-color: #f3f3f3">
                    <div class="item_pic" style="width: 437px; height: 288px;">
                         <el-image :src="history_box" fit="cover" style="width: 100%; height: 100%;"></el-image>
                         <div class="dis_center" style="width: 100%; height: 100%; position: absolute; top: 0; left: 0;">
                              <el-image :src="hispic7" fit="cover" class="item_pic_con" style="width: 340px; height: 200px;"></el-image>
                         </div>

                         <div style="width: 100%; height: 29px; position: relative; margin-top: 50px;" class="dis_center">
                              <el-image :src="history_title" fit="cover" style="width: 280px; height: 33px;"></el-image>
                              <div class="dis_center f_16" style="width: 100%; height: 100%; color: #fff; position: absolute; top: 0; left: 50%; transform: translateX(-50%); letter-spacing: 4px;">{{ '拓疆' }}</div>
                         </div>
                         
                    </div>
                    <div class="f_14 item_text" style="width: 350px; line-height: 2.4; font-size: 15px;">{{ $t('home.h3') }}</div>

               </div>


          </div>
     </div>

  </div>
</template>

<script>
import history_left from '@/assets/home/history_left.png';
import history_right from '@/assets/home/history_right.png';
import history_box from '@/assets/home/his_box.png';
import history_title from '@/assets/home/his_title.png';

import hispic1 from '@/assets/home/hispic1.jpg';
import hispic2 from '@/assets/home/hispic2.jpg';
import hispic3 from '@/assets/home/hispic3.jpg';
import hispic4 from '@/assets/home/hispic4.jpg';
import hispic5 from '@/assets/home/hispic5.jpg';
import hispic6 from '@/assets/home/hispic6.jpg';
import hispic7 from '@/assets/home/hispic7.jpg';



export default {
     data(){
          return {
               history_left,
               history_right,
               history_box,
               history_title,

               page_index: 0,
               hispic1,
               hispic2,
               hispic3,
               hispic4,
               hispic5,
               hispic6,
               hispic7,

               his_arr: [
                       {
                            pic: hispic1,
                            year: '2020',
                            month: 'home.his[2]',
                            title: '破局',
                            zh: '中天汇宇投资集团旗下首家互联网科技公司——哈尔滨环宇互娱科技有限公司正式成立。',
                            en: 'Zhongtian Huiyu rapid development, more than ten industrial subsidiaries have been established'
                       },
                       {
                            pic: hispic2,
                            year: '2021',
                            month: 'home.his[3]',
                            title: '聚势',
                            zh: '哈尔滨中天汇宇投资集团总部正式成立。',
                            en: 'Harbin Zhongtian Huiyu Investment Group headquarters was officially established.'
                       },
                       {
                            pic: hispic3,
                            year: '2021',
                            month: 'home.his[12]',
                            title: '求进',
                            zh: '集团旗下宠物专业综合服务商——沐知其生物科技有限公司成立。',
                            en: "The group's pet professional comprehensive service provider - Mu Zhi its Biotechnology Co., Ltd. was established."
                       },
                       {
                            pic: hispic4,
                            year: '2022',
                            month: 'home.his[11]',
                            title: '聚焦',
                            zh: '集团旗下高端国货美妆公司——哈尔滨高美妍商贸有限公司成立。',
                            en: "The group's high-end domestic beauty cosmetics company - Harbin High Meiyan Trading Co., Ltd. was established."
                       },
                       {
                            pic: hispic5,
                            year: '2023',
                            month: 'home.his[4]',
                            title: '赋能',
                            zh: '集团旗下高端餐饮管理企业——哈尔滨食锁餐饮管理有限公司成立。',
                            en: "The group's high-end catering management enterprise - Harbin Food lock Catering Management Co., Ltd. was established."
                       },
                       {
                            pic: hispic6,
                            year: '2023',
                            month: 'home.his[11]',
                            title: '探索',
                            zh: '集团首家MCN机构——杭州彩世文化传媒有限公司成立。',
                            en: "The group's first MCN institution - Hangzhou Caishi Culture Media Co., Ltd. was established."
                       },
                       
                  ]

          }
     },
     computed: {
          page_count(){
              return parseInt(this.his_arr.length/ 3) + (this.his_arr.length% 3> 0? 1: 0)
          }
     },
     methods: {
          page(num){
               this.page_index= this.page_index+ Number(num)
          },   
     }


}
</script>

<style scoped>
     .line_b{
          width: 1002px;
          min-width: 1002px;
          height: 520px;
          position: relative;
          margin: 0 auto;
          /* padding-top: 60px;
          box-sizing: border-box; */
          align-items: flex-start;
     }
     .line{
          width: calc(100% - 72px);
	     height: 1px;
          background-color: #276df9;
          opacity: 1;
          /* transition: all .6s .1s; */
     }
     .his_conb_over{
          width: calc(100% - 72px);
          height: 520px;
          position: absolute;
          top: 0;
          left: 36px;
          overflow: hidden;
     }
     .his_conb{
          position: absolute;
          top: 0;
          left: 0;
          transition: left .7s ease-in-out;
          display: flex;
          flex-wrap: nowrap;
     }
     .his_item{
          width: 310px;
          height: 500px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          flex-shrink: 0;
          box-sizing: border-box;
     }
     .item_pic{
          width: 279px;
	     height: 184px;
          position: relative;
     }
     .item_pic_con{
          width: 238px;
	     height: 134px;
          transition: all .5s;
     }
     .his_item:hover .item_pic_con{
          transform: scale(1.3);
     }
     .item_time{
          width: 60px;
	     height: 60px;
          background-color: #f3f3f3;
          border: 1px solid #115efa;
          border-radius: 50%;
          box-sizing: border-box;
          padding: 6px;
          transition: all .3s;
     }
     .item_con{
          width: 100%; 
          height: 100%; 
          background-color: #115efa; 
          border-radius: 50%;
          transition: all .3s;
     }
     .f_12{
          font-size: 12px;
          transition: all .6s;
     }
     .his_item:hover .item_time{
          border: 1px solid #fab511;
          width: 80px;
	     height: 80px;
     }
     .his_item:hover .item_con{
          width: 100%; 
          height: 100%; 
          background-color: #fab511; 
     }
     .his_item:hover .f_12{
          font-size: 15px;
     }
     .item_text{
          line-height: 1.7; 
          letter-spacing: 1px; 
          color: #000;
          width: 250px; 
          margin-top: 30px;
          opacity: .63;
          transition: all .3s;
     }
     .his_item:hover .item_text{
          opacity: 1;
     }

     .last_item:hover .item_text{
          opacity: 1;
     }
     /* .last_item:hover .item_pic_con{
          transform: scale(1.37);
     } */
</style>